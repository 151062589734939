<template>
  <div class="merge-alarm-statistics" ref="mergeAlarmStatistics">
    <div class="form-area" ref="form">
      <formSearch
        :alarmStatusList="alarmStatusList"
        :alarmOptions="alarmOptions"
        :alarmType="alarmType"
        @getFormVal="onSubmit"
      >
      </formSearch>
    </div>
    <el-table id="outputTable" :data="tableData" stripe :height="tableHeight-46">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="alarmType"
        label="报警类型"
        width="160"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{
            alarmTypeObj[Number(scope.row.alarmType)]
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="alarmLevel" label="报警等级">
        <template slot-scope="scope">
          {{ alarmLevelObj[Number(scope.row.alarmLevel)] }}
        </template>
      </el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        width="160"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="driverName" label="驾驶员姓名"></el-table-column>
      <el-table-column prop="alarmSource" label="报警来源">
        <template slot-scope="scope">{{
          alarmSourceObj[Number(scope.row.alarmSource)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="alarmSignTime"
        width="180"
        label="报警发生时间"
      ></el-table-column>
      <el-table-column
        prop="alarmEndTime"
        width="180"
        label="报警结束时间"
      ></el-table-column>
      <el-table-column
        prop="percentage"
        label="报警最高车速/限速值"
        width="160"
      >
        <template slot-scope="scope">
          {{ computedValue(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column prop="alarmStatus" label="报警状态">
        <template slot-scope="scope">
          {{ statusObj[Number(scope.row.alarmStatus)] }}
        </template>
      </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import formSearch from "./formSearch.vue"
import {
  queryCheckMergeAlarmPageListAPI,
} from "@/api/lib/gps-api.js";
import {
  queryDictsByCodes
} from '@/api/lib/api.js';
import {
  formatDict,
} from '@/common/utils/index'
export default {
  components: {
    formSearch,   
  },
  props:{
    height:{
      type: Number,
    },
    alarmType:{
      type:Array,
      default:()=>[]
    }
  },
  watch: {
    height (val, oldVal) {
      setTimeout(() => {
        this.tableHeight = val;
      }, 200);
    },
  },
  data () {
    return {
      tableData: [],
      tableHeight: 0,
      total: 1,
      form: {
        pageSize: 10,
        currentPage: 1,
      },
      alarmItem: null,
      alarmLevelObj: {},//报警等级
      alarmSourceObj: {},//报警来源
      disposeTypeObj: {},
      disposeWayObj: {},
      //报警状态 （1开始2结束3持续）
      alarmStatusList: [
        {
          dictValue: "开始",
          dictCode: 1
        },
        {
          dictValue: "已结束",
          dictCode: 2
        },
        {
          dictValue: "持续中",
          dictCode: 3
        },
      ],
      alarmOptions: [
        {
          dictValue: "疲劳驾驶报警",
          dictCode: 1001
        },
        {
          dictValue: "超速报警",
          dictCode: 2012
        },
        {
          dictValue: "路网图超速报警",
          dictCode: 2051
        }
      ],
      alarmTypeObj: {},//报警类型
      statusObj: {},//报警状态
    }
  },
  created () {
    this.getDicts();
    this.statusObj = formatDict(this.alarmStatusList);
    this.alarmTypeObj = formatDict(this.alarmOptions);
  },
  mounted () {
   
  },
  methods: {
    computedValue (row) {
      //疲劳驾驶展示-
      if (row.alarmType == 1001) {
        return "-"
      } else {
        return `${row.velocity}/${row.limitValue}`
      }
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getDataList();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getDataList();
    },
    getDataList () {
      queryCheckMergeAlarmPageListAPI(this.form).then(res => {
        if (res.code === 1000) {
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    },
    /** 数据为空默认显示- */
    formatNull (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : cellValue
    },
    onSubmit (val) {
      this.form = { ...val };
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getDataList();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({
        parentCodes:
          'BJDJ,BJLY,CLLX,BJCLFS'
      }).then((res) => {
        if (res.code === 1000) {
          // this.dictList = {
          //   alarmLevelList: res.data.BJDJ,
          // }
          this.alarmLevelObj = formatDict(res.data.BJDJ);
          this.alarmSourceObj = formatDict(res.data.BJLY);
          this.disposeTypeObj = formatDict(res.data.CLLX);
          this.disposeWayObj = formatDict(res.data.BJCLFS);
        }
      }).then(() => {
        // this.getAlarmTypeFn()
        // this.getAlarmData()
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.merge-alarm-statistics {
  
}
</style>