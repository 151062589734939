var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "mergeAlarmStatistics", staticClass: "merge-alarm-statistics" },
    [
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c("formSearch", {
            attrs: {
              alarmStatusList: _vm.alarmStatusList,
              alarmOptions: _vm.alarmOptions,
              alarmType: _vm.alarmType
            },
            on: { getFormVal: _vm.onSubmit }
          })
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            id: "outputTable",
            data: _vm.tableData,
            stripe: "",
            height: _vm.tableHeight - 46
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmType",
              label: "报警类型",
              width: "160",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.alarmTypeObj[Number(scope.row.alarmType)])
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmLevel", label: "报警等级" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.alarmLevelObj[Number(scope.row.alarmLevel)]
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "cph", label: "车牌号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属车队",
              width: "160",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "driverName", label: "驾驶员姓名" }
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmSource", label: "报警来源" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(_vm.alarmSourceObj[Number(scope.row.alarmSource)])
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "alarmSignTime",
              width: "180",
              label: "报警发生时间"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmEndTime", width: "180", label: "报警结束时间" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "percentage",
              label: "报警最高车速/限速值",
              width: "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(_vm.computedValue(scope.row)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "alarmStatus", label: "报警状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.statusObj[Number(scope.row.alarmStatus)]) +
                        " "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }